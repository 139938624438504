<template>
  <div class="container-fluid my-2">
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-4">
        <b-nav align="center">
          <b-nav-item @click="year -= 1" class="link-l">
            <font-awesome-icon icon="angle-double-left"/>
            {{year-1}}
          </b-nav-item>
          <h4 class="my-0">
            Lesson Attendance for {{ year }}
          </h4>
          <b-nav-item  @click="year += 1" class="link-r">
            {{year+1}}
            <font-awesome-icon icon="angle-double-right"/>
          </b-nav-item>
        </b-nav>
      </div>
      <div class="col-sm-4">

      </div>
    
    </div>
    <ChartTable
      ref="chart"
      :cell="cell"
      :year="year"
      lessons=True
      @cell-click="onCellClick">
    </ChartTable>

    <b-modal ref="lessonModal"
             id="add-lesson-modal"
             v-bind:title="lessonForm.edit_mode ? 'Edit lesson for '+ student.name +' on '+ student.lesson_date : 'New lesson for '+ student.name +' on '+ student.lesson_date"
             hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
        <b-input-group id="form-title-group" prepend="Lesson Length" label-for="form-add-lesson-length" class="my-1">
            <b-form-select id="form-add-lesson-length" v-model="lessonForm.length" :options="lessonForm.lengths" required>
            </b-form-select>
        </b-input-group>
        <b-input-group id="form-points-group" prepend="Attendance" class="my-1">
          <table style="border: 1px solid lightgrey;">
            <td >
              <div class="star-container align-middle" id="star-menu-times" @click="function(){lessonForm.points = 0;}">
                <font-awesome-icon icon="times" v-bind:class="lessonForm.points == 0 ? 'star-times star-selected' : 'star-times'" size="2x" fixed-width style="margin-top: 3px;"/>
              </div>
            </td>
            <td>
              <div class="star-container align-middle" id="star-menu-gold" @click="function(){lessonForm.points = 5;}">
                <font-awesome-icon icon="star" v-bind:class="lessonForm.points == 5 ? 'star-gold star-selected' : 'star-gold'" size="2x" fixed-width style="margin-top: 3px;"/>
              </div>
            </td>
            <td>
              <div class="star-container align-middle" id="star-menu-silver" @click="function(){lessonForm.points = 4;}">
                <font-awesome-icon icon="star" v-bind:class="lessonForm.points == 4 ? 'star-silver star-selected' : 'star-silver'" size="2x" fixed-width style="margin-top: 3px;"/>
              </div>
            </td>
            <td>
              <div class="star-container align-middle" id="star-menu-red" @click="function(){lessonForm.points = 3;}">
                <font-awesome-icon icon="star" v-bind:class="lessonForm.points == 3 ? 'star-red star-selected' : 'star-red'" size="2x" fixed-width/>
              </div>
            </td>
          </table>
        </b-input-group>


        <div class="my-1" v-if="!lessonForm.edit_mode">
          <b-input-group id="form-price-group" prepend="Should be charged: " label-for="form-charge-price">

            <b-button :variant="lessonForm.do_charge ? 'success' : 'secondary'" @click="lessonForm.do_charge = !lessonForm.do_charge" style="border-radius: 0px; border-width: 0px;">
              <span v-if="lessonForm.do_charge" >
                <font-awesome-icon icon="check-square"/> yes
              </span>
              <span v-else>
                <font-awesome-icon icon="square"/> no
              </span>
              
            </b-button>

            <!--
            <span class="input-group-text" style="border-radius: 0px; border-width: 0px;">
              Cost: ${{student.lesson_price && lessonForm.do_charge ? student.lesson_price / 100 : 0}}
            </span>
            
            <span class="input-group-text" style="border-radius: 0px; border-left-width: 0px; border-right-width: 0px;">
              Until: 
            </span>
            <b-form-input  type="number" step="0.01" id="form-price" v-model="lessonForm.charge.amount" v-bind:disabled="!lessonForm.do_charge">
            </b-form-input>
            -->

          </b-input-group>
        </div>

        <b-input-group id="form-minutes-group" prepend="Practicing Minutes" label-for="form-minutes">
          <b-form-input type="number" step="1" id="form-minutes" v-model="lessonForm.minutes">
          </b-form-input>
        </b-input-group>

        <hr>
        <b-button type="submit" v-bind:variant="lessonForm.edit_mode ? 'primary' : 'success'">
          {{lessonForm.edit_mode ? 'Save' : 'Create'}}
        </b-button>
        <b-button type="reset" variant="secondary" class="mx-1">
          Cancel
        </b-button>
        <b-button v-if="lessonForm.edit_mode" variant="danger" @click="deleteLesson(lessonForm.id)" class="float-right">
          Delete Lesson
        </b-button>
      </b-form>
    </b-modal>
    
  </div>
</template>

<script>
import ChartTable from '../components/ChartTable'
import StarChartCell from "../components/StarChartCell"

import { ApiFactory } from "../api/ApiFactory";
const LessonLengthAPI = ApiFactory.get("lesson_length");
const LessonAPI = ApiFactory.get("lessons");

export default {
  data: ()=> {
    return {
      year: new Date().getUTCFullYear(),
      cell: StarChartCell,
      lessonForm: {
        edit_mode: false,
        id: 0,
        length: 0,
        lengths: null,
        points: 4,
        minutes: null,
        do_charge: true,
        charge: {id: null}
      },
      student: {
        name: "",
        id: 0,
      },
    }
  },

  components: {
    ChartTable
  },

   created: function(){
      if (this.$route.params.year != undefined){
        this.year = Number(this.$route.params.year)
      }
  },

  methods: {
    async onCellClick({student_id, week, exists}){

      for (var i=0; i < this.$refs.chart.students.length; i++){
        if (this.$refs.chart.students[i].id == student_id){
          this.student = this.$refs.chart.students[i];
          break;
        }
      }

      this.student.lesson_date = week.getUTCFullYear() + '-' + (1 + week.getUTCMonth()) + '-' + week.getUTCDate();

      this.initForm();


      if (exists){
        this.lessonForm.edit_mode = true;
        this.lessonForm.id = this.$refs.chart.lessons[student_id][week.getTime()].id;
        this.lessonForm.length = this.$refs.chart.lessons[student_id][week.getTime()].length;
        this.lessonForm.points = this.$refs.chart.lessons[student_id][week.getTime()].points;
        this.lessonForm.minutes = this.$refs.chart.lessons[student_id][week.getTime()].minutes;
      }else{
        this.lessonForm.length = this.student.default_len_id;
      }
      
      this.$refs.lessonModal.show();
    },

    async initForm(){
      this.lessonForm.length = 0;
      this.lessonForm.edit_mode = false;
      this.lessonForm.points = 4;
      this.lessonForm.minutes = null;
      this.lessonForm.charge = {id: null}
      this.lessonForm.do_charge = true;
      
      if (this.lessonForm.lengths == null){
        this.lessonForm.lengths = [];

        const {data} = await LessonLengthAPI.get();
        for (var i=0; i < data.length; i++){
          this.lessonForm.lengths.push({value: data[i].id, text: data[i].duration + "m"});
        }
      }
    },

    async onSubmit(evt){
      evt.preventDefault();
      this.$refs.lessonModal.hide();

      const payload = {
        student_id: this.student.id,
        length_id: this.lessonForm.length,
        points: this.lessonForm.points,
        minutes: this.lessonForm.minutes ? this.lessonForm.minutes : null,
        week_of: this.student.lesson_date,
      };

      if (this.lessonForm.edit_mode){
        payload.id = this.lessonForm.id;
        await LessonAPI.updateLesson(payload).then(resp =>{
          if (resp.status == 200){
            var date = new Date(resp.data.week_of);
            this.$set(this.$refs.chart.lessons[resp.data.student], date.getTime(), resp.data)
          }
        });
      }else{
        payload.charge = this.lessonForm.do_charge
        await LessonAPI.createLesson(payload).then(resp =>{
          if (resp.status == 201){
            if (this.$refs.chart.lessons[resp.data.student] == undefined){
              this.$refs.chart.lessons = {}
              this.$refs.chart.getLessons();
              this.$refs.chart.is_loaded = true;
            }else{
              var date = new Date(resp.data.week_of);
              this.$set(this.$refs.chart.lessons[resp.data.student], date.getTime(), resp.data)
            }
          }
        });        
      }
    },

    async onReset(){
      this.$refs.lessonModal.hide();
      this.initForm();
    },

    async deleteLesson(id){
      await LessonAPI.deleteLesson(id);
      this.$refs.lessonModal.hide();
      this.initForm();
      this.$refs.chart.lessons = {}
      await this.$refs.chart.getLessons();
      this.$refs.chart.is_loaded = true;
    },
  }
}
</script>

<style scoped>
  .link-l a{
    padding-left: 0px;
  }
  .link-r a{
    padding-right: 0px;
  }

</style>